import logohalfwish from '../assets/images/logo.png';

const MobilePage = () => {
  return (
    <div className="wrapper mobile-support">
      <article>
        <div className="mobile-info">
          <img src={logohalfwish} alt="" className="img-logo" />
          <h4>Mobile version coming soon!</h4>
        </div>
      </article>
    </div>
  );
};

export default MobilePage;
